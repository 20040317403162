import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  Typography,
} from "@visa/nova-react";
import useFocusTrap from "../../../utils/useFocusTrap.tsx";
import React, { forwardRef, useImperativeHandle } from "react";
import { LATEST_DRAFT_OVERRIDE_MESSAGE } from "../../../utils/constants.jsx";

export const ConfirmSaveModal = forwardRef(
  ({ version, flowInfo, saveConfirmed }, fref) => {
    const { onKeyNavigation, ref } = useFocusTrap();

    useImperativeHandle(fref, () => ({
      showModal() {
        ref.current?.showModal();
      },
      closeModal() {
        ref.current?.close();
      },
    }));

    return (
      <>
        <Dialog
          aria-describedby="confirm-save-dialog-description"
          aria-labelledby="confirm-save-dialog-title"
          id="dialog"
          ref={ref}
          onKeyDown={(e) => onKeyNavigation(e, ref.current?.open)}
        >
          <DialogContent>
            <DialogHeader id="confirm-save-dialog-title">
              {LATEST_DRAFT_OVERRIDE_MESSAGE}
            </DialogHeader>
            <Typography id="confirm-save-dialog-description">
              This will override the current latest version &nbsp;
              <b>{flowInfo?.latestVersion}</b> to your selected version &nbsp;
              <b>{version}</b>.
            </Typography>
            <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-end">
              <Button onClick={saveConfirmed}>
                Ok, Replace Latest Version
              </Button>
              <Button
                colorScheme="secondary"
                onClick={() => ref.current?.close()}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
          <DialogCloseButton onClick={() => ref.current?.close()} />
        </Dialog>
      </>
    );
  }
);
