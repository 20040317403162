import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals.js";

import { ReactComponent as Logo } from "@visa/nova-assets/logos/visa/visa_vbm_blu.svg";
import Footer from "@visa/nova-react/footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />

    <Footer className="v-gap-15">
      <div className="v-flex v-mr-1">
        <Logo
          style={{
            blockSize: "23px",
            inlineSize: "71px",
            marginInlineEnd: "16px",
          }}
        />
      </div>
      <div className="v-flex v-flex-grow v-flex-wrap v-justify-content-between v-gap-42">
        {`Copyright © ${new Date().getFullYear()} Visa Inc. All Rights Reserved`}
      </div>
    </Footer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
