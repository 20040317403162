import { VisaWarningLow } from "@visa/nova-icons-react";

const NodeErrorPanel = ({ nodeData, hasOutEdges }) => {
  return (
    <>
      <span>{hasOutEdges}</span>
      <span>{nodeData.finalNode}</span>
      {nodeData.missingParams.length > 0 && (
        <div className="missing-param error">
          <VisaWarningLow />
          <p>&nbsp;Missing Parameter: {nodeData.missingParams}</p>
        </div>
      )}
      {hasOutEdges === false && nodeData.finalNode !== true && (
        <div className="warning">
          <VisaWarningLow />
          <p>&nbsp;Should Be Marked as Final Flow State</p>
        </div>
      )}
    </>
  );
};

export default NodeErrorPanel;
