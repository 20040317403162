import { Handle } from "reactflow";
import { VisaAddTiny } from "@visa/nova-icons-react";

const OutHandle = () => {
  return (
    <>
      <Handle className="out-handle" type="source" position="right">
        <VisaAddTiny className="out-handle-icon" />
      </Handle>
    </>
  );
};

export default OutHandle;
