import {
  InputContainer,
  InputControl,
  Select,
  Utility,
  Button,
} from "@visa/nova-react";
import {
  VisaChevronDownTiny,
  VisaFileUploadTiny,
  VisaSaveTiny,
  VisaDeleteTiny,
} from "@visa/nova-icons-react";
import { isSandboxEnvironment } from "../../utils/authUtils";

const FlowDetails = ({
  flowDetails,
  flowInfo,
  onVersionSelectionChange,
  onSaveFlow,
  onAdjustLayout,
  onUnPublish,
  selectedVersion,
  envProfile,
}) => {
  return (
    <>
      <div
        className="flow-view-title v-justify-content-between"
        style={{ maxWidth: "calc(100vw - 270px)" }}
      >
        <Utility tag="fieldset" vFlex vAlignContent="center" vFlexRow vGap={6}>
          <span
            style={{ marginTop: 10, marginRight: 10 }}
            className="v-xs-container-hide  v-md-container-hide v-mobile-container-hide"
          >
            <strong>Flow Name:</strong> &nbsp;
            <span className="flow-title-val">{flowDetails.flowName}</span> -
            <strong>Last Updated:</strong> &nbsp;
            <span className="flow-title-val">
              <strong>{flowInfo.latestVersionTimeStamp}</strong>
            </span>
          </span>
          <InputContainer style={{ maxWidth: 200, paddingTop: 0 }}>
            <Select
              id="select-inline-field"
              name="select-inline-example"
              aria-label="select-version"
              onChange={(event) =>
                onVersionSelectionChange(event.currentTarget.value)
              }
            >
              <option value={flowInfo.latestVersion}>
                Latest Version - {flowInfo.latestVersionTimeStamp}
              </option>
              <option
                disabled={!flowInfo.publishedVersion}
                value={flowInfo.publishedVersion}
              >
                Published - &nbsp;
                {flowInfo.publishedIndicator !== "Y"
                  ? "N/A"
                  : flowInfo.publishedVersionTimeStamp}
              </option>
              <option value={1}>Reset To Template</option>
            </Select>
            <InputControl>
              <VisaChevronDownTiny />
            </InputControl>
          </InputContainer>

          <Button
            colorScheme="secondary"
            onClick={() => onAdjustLayout()}
            style={{ margin: 5, marginRight: 25 }}
          >
            Adjust Layout
          </Button>
          <Button
            iconTwoColor
            onClick={() => onSaveFlow()}
            style={{ margin: 5 }}
          >
            <VisaSaveTiny />
            Save
          </Button>

          {(flowInfo.publishedIndicator !== "Y" ||
            selectedVersion !== flowInfo.publishedVersion) && (
            <Button
              iconTwoColor
              onClick={() => onSaveFlow(true)}
              style={{ margin: 5 }}
              disabled={isSandboxEnvironment(envProfile)}
            >
              <VisaFileUploadTiny />
              Publish
            </Button>
          )}
          {flowInfo.publishedIndicator === "Y" &&
            selectedVersion === flowInfo.publishedVersion && (
              <>
                <span
                  style={{ marginTop: 10, marginRight: 10 }}
                  className="v-xs-container-hide  v-md-container-hide v-mobile-container-hide"
                >
                  <strong>Published:</strong> &nbsp;
                  <span className="flow-title-val">
                    <strong>{flowInfo.publishedVersionTimeStamp}</strong>
                  </span>
                </span>
                <Button
                  colorScheme="secondary"
                  iconTwoColor
                  onClick={() => onUnPublish()}
                  style={{ margin: 5 }}
                  disabled={isSandboxEnvironment(envProfile)}
                >
                  <VisaDeleteTiny />
                  Un-Publish
                </Button>
              </>
            )}
        </Utility>
      </div>
    </>
  );
};
export default FlowDetails;
