import React, { useState, useEffect } from "react";
import Typography from "@visa/nova-react/typography";

import ContentCard from "@visa/nova-react/content-card";
import ContentCardBody from "@visa/nova-react/content-card-body";
import ContentCardTitle from "@visa/nova-react/content-card-title";
import Divider from "@visa/nova-react/divider";
import apiConfig from "../../utils/apiConfig";

export default () => {
  const [nodeTypes, setNodeTypes] = useState([]);
  const [bid, setBid] = useState(null);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      setBid(currentUser.userBid);
    }

    if (bid !== null)
      apiConfig
        .get("/banking-assistant-web/v1/nodetype/bid/" + bid)
        .then((res) => {
          setNodeTypes(res.data);
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [bid]);

  return (
    <aside id="sidebar-aside" style={{ height: "calc(100vh - 140px)" }} tabIndex='0'>
      <div className="sidebar-title">
        <Typography variant="label-active">
          Available Functionalities
        </Typography>
      </div>
      {nodeTypes.map((nodeType) => {
        return (
          <div
            key={nodeType.nodeTypeId}
            className="sidebar-draggable"
            onDragStart={(event) => onDragStart(event, nodeType.nodeTypeId)}
            draggable
          >
            <div>
              <ContentCard className="content-card-sidebar">
                <ContentCardBody className="v-flex v-flex-col v-gap-4">
                  <ContentCardTitle className="v-typography-headline-4">
                    {nodeType.nodeTypeName}
                  </ContentCardTitle>
                  <Divider />
                  <Typography className="v-pt-4">
                    {nodeType.nodeTypeDescription}
                  </Typography>
                </ContentCardBody>
              </ContentCard>
            </div>
          </div>
        );
      })}
    </aside>
  );
};
