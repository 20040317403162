import { Progress, ProgressLabel } from "@visa/nova-react";

export const ProgressBar = () => {
  return (
    <>
      <Progress className="v-mb-8" id="top-progress-bar" />
      <ProgressLabel htmlFor="progress-bar"></ProgressLabel>
    </>
  );
};
