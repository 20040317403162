import React, { useState, useEffect } from "react";
import apiConfig from "../utils/apiConfig";
import { VisaChevronDownTiny } from "@visa/nova-icons-react";
import { InputContainer, InputControl, Label, Select } from "@visa/nova-react";
import { BASE_PATH } from "../utils/authUtils";

const UserSelect = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const onSelectUser = (value) => {
    console.log("SELECTED: ", value);
    doLogin(value);
  };

  const loadUsers = () => {
    apiConfig
      .get("/banking-assistant-web/v1/userinfo/basic")
      .then((res) => {
        setUsers(res.data);
        console.log(users);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const doLogin = (volId) => {
    console.log(" login for ", volId);
    apiConfig
      .post(
        "/authenticate",
        {},
        {
          headers: {
            sm_login: volId,
          },
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((res) => {
        console.log(res);
        const csrfToken = res.headers.get("X_csrf_token");

        localStorage.setItem("currentUser", JSON.stringify(res.data));
        localStorage.setItem("csrfToken", csrfToken);
        window.location = BASE_PATH;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Label htmlFor="select-user-field">Log in as</Label>
        <InputContainer>
          <Select
            aria-describedby="select-user-message"
            id="select-user-field"
            name="select-user"
            onChange={(event) => onSelectUser(event.currentTarget.value)}
            defaultValue={""}
          >
            <option value="">---</option>
            {users.map((user) => (
              <option key={user.volId} value={user.volId}>
                {user.volId} ({user.firstName} {user.lastName}) BID: &nbsp;
                {user.userBid}
              </option>
            ))}
          </Select>
          <InputControl>
            <VisaChevronDownTiny />
          </InputControl>
        </InputContainer>
      </div>
    </>
  );
};

export default UserSelect;
