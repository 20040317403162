import {
  Typography,
  ContentCard,
  ContentCardTitle,
  ContentCardBody,
  Divider,
} from "@visa/nova-react";
import { VisaSecurityLockHigh } from "@visa/nova-icons-react";
import React, { useEffect, useState } from "react";
import apiConfig from "../utils/apiConfig";
import { LOGIN_PATH } from "../utils/authUtils";

const Logout = () => {
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    } else {
      window.close();
      /**
       * If browser fails to close the window, redirect it to login page.
       */
      window.location = LOGIN_PATH;
    }
  }, [countDown]);

  useEffect(() => {
    doLogout();
  }, []);

  const doLogout = () => {
    console.log(" logout Api call ");
    apiConfig
      .post("/logout", {})
      .then((res) => {
        console.log(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          marginTop: 20,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        <ContentCard>
          <ContentCardBody className="v-flex v-flex-col v-gap-4">
            <VisaSecurityLockHigh />
            <ContentCardTitle variant="headline-4">
              You are successfully logged out.
            </ContentCardTitle>
            <Divider />
            <Typography className="v-pt-4">
              This window will close in {countDown} seconds...
            </Typography>
          </ContentCardBody>
        </ContentCard>
      </div>
    </>
  );
};

export default Logout;
