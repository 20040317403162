import { Banner, BannerContent, Badge, Typography } from "@visa/nova-react/";

export const getParamsView = (messageType, paramList) => {
  return (
    <div className="v-flex v-align-items-center v-gap-12 v-pt-12">
      <Banner messageType={messageType}>
        <BannerContent className="node-param-strip">
          {paramList?.length > 0 && (
            <Typography variant="body-2">Parameters</Typography>
          )}
          {paramList.map((param) => (
            <Badge key={param} badgeType="warning">
              {param}
            </Badge>
          ))}
        </BannerContent>
      </Banner>
    </div>
  );
};

export const getContentCardClassName = (nodeData, hasOutEdges) => {
  let className = "";
  if (nodeData && nodeData.missingParams && nodeData.missingParams.length > 0) {
    className = className.concat(" missing-param error ");
  }
  if (nodeData && nodeData.finalNode && nodeData.finalNode === true) {
    className = className.concat(" final-node-out-handle ");
  }
  if (hasOutEdges === false && nodeData.finalNode !== true) {
    className = className.concat(" warning ");
  }
  return className;
};
