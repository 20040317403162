import { KeyboardEvent, MutableRefObject, useEffect, useRef, useState } from 'react';

type UseFocusTrapOptions<HTMLElementType extends HTMLElement = HTMLDialogElement> = {
  /** Document object */
  document?: Document;
  /** DOM elements selector */
  querySelector?: string;
  /** Ref for the elements container */
  ref?: MutableRefObject<HTMLElementType | null>;
};

const defaultOptions = {
  document,
  querySelector: 'a[href], button:not(disabled), textarea:not(disabled), input:not(disabled), select:not(disabled)',
} satisfies Partial<UseFocusTrapOptions>;

/**
 * @docs {@link https://productdesignsystem.visa.com/react/hooks/use-focus-trap | See Docs}
 * @description This hook is used to trap focus inside a container.
 * @related dialog, panel
 */
export const useFocusTrap = <HTMLElementType extends HTMLElement = HTMLDialogElement>(
  useFocusTrapOptions?: UseFocusTrapOptions<HTMLElementType>
) => {
  const customRef = useRef<HTMLElementType | null>(null);
  const [focusableElements, setFocusableElements] = useState<NodeListOf<HTMLElementType> | []>([]);

  const options: UseFocusTrapOptions<HTMLElementType> = { ...defaultOptions, ...useFocusTrapOptions };
  const ref = options.ref || customRef;
  const firstElement: HTMLElement | null = focusableElements[0];
  const lastElement: HTMLElement | null = focusableElements[focusableElements.length - 1];

  // make sure the focus is trapped inside the dialog
  const onKeyNavigation = (e: KeyboardEvent, open = false) => {
    if (open && e.key === 'Tab') {
      if (e.shiftKey && document?.activeElement === firstElement) {
        e.preventDefault();
        lastElement.focus();
      } else if (!e.shiftKey && document?.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  useEffect(() => {
    setFocusableElements(
      (ref.current?.querySelectorAll && ref.current?.querySelectorAll<HTMLElementType>(options.querySelector!)) || []
    );
  }, [options.querySelector, ref]);

  return {
    /** Function that handles on key down for navigation */
    onKeyNavigation,
    /** Ref object to use if ref isn't supplied in props */
    ref,
  };
};

export default useFocusTrap;

useFocusTrap.displayName = 'useFocusTrap';

useFocusTrap.defaultProps = {
  document,
  querySelector: 'a[href], button:not(disabled), textarea:not(disabled), input:not(disabled), select:not(disabled)',
};