import { useEdges } from "reactflow";
import React, { useState, useEffect } from "react";
import {
  ContentCard,
  ContentCardBody,
  ContentCardTitle,
  Divider,
  Typography,
} from "@visa/nova-react";
import NodeContext from "./node-common/NodeContext";
import InHandle from "./node-common/InHandle";
import OutHandle from "./node-common/OutHandle";
import NodeErrorPanel from "./node-common/NodeErrorPanel";
import {
  getContentCardClassName,
  getParamsView,
} from "./node-common/nodeCommonUtils";
import {
  NODE_TYPE_START,
  nodeTypeColors,
  nodeTypeIcons,
  nodeTypeParams,
} from "../../utils/constants";

const FlowNode = ({ data: nodeData }) => {
  const [hasOutEdges, setHasOutEdges] = useState(false);
  const edges = useEdges();

  useEffect(() => {
    setHasOutEdges(edges.some((e) => e.source === nodeData.id));
  }, [edges]);
  const isStartNode = nodeData.nodeTypeId === NODE_TYPE_START;

  return (
    <>
      <NodeContext nodeData={nodeData} hasOutEdges={hasOutEdges} />
      <NodeErrorPanel nodeData={nodeData} hasOutEdges={hasOutEdges} />
      <ContentCard className={getContentCardClassName(nodeData, hasOutEdges)}>
        <ContentCardBody className="v-flex v-flex-col v-gap-4">
          {isStartNode && <span className="start-node-marker">Flow Start</span>}
          {nodeData.finalNode === true && (
            <span className="final-node-marker">Flow End</span>
          )}
          {nodeTypeIcons[nodeData.nodeTypeId]}
          <ContentCardTitle className="v-typography-headline-4">
            {nodeData.nodeName}
          </ContentCardTitle>
          <Divider />
          {!isStartNode && <InHandle />}
          <Typography className="v-pt-4 node-desc">
            {nodeData.nodeDescription}
          </Typography>
          {getParamsView(
            nodeTypeColors[nodeData.nodeTypeId],
            nodeTypeParams[nodeData.nodeTypeId]
          )}
          <OutHandle />
        </ContentCardBody>
      </ContentCard>
    </>
  );
};

export default FlowNode;
