import { VisaDeleteLow, VisaEditLow } from "@visa/nova-icons-react";
import { NodeToolbar, Position } from "reactflow";
import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  Label,
  Input,
  Textarea,
  InputContainer,
  Accordion,
  AccordionHeading,
  AccordionPanel,
  AccordionToggleIcon,
  Typography,
  useAccordion,
  CheckboxPanel,
  Checkbox,
  Utility,
  InputMessage,
} from "@visa/nova-react";
import useFocusTrap from "../../../utils/useFocusTrap.tsx";
import CodeBlockDisplay from "../../common/CodeBlockDisplay";
import { NODE_TYPE_START } from "../../../utils/constants.jsx";

const NodeContext = ({ nodeData, hasOutEdges }) => {
  const { onKeyNavigation, ref } = useFocusTrap();
  const [name, setName] = useState(nodeData.nodeName || "");
  const [desc, setDesc] = useState(nodeData.nodeDescription || "");
  const [instructions, setInstructions] = useState(nodeData.instructions || "");
  const [tools] = useState(JSON.stringify(nodeData.tools, null, 2));
  const [message, setMessage] = useState(nodeData.nodeMessage ?? "");
  const [finalNode, setFinalNode] = useState(nodeData.finalNode ?? "");

  const { isIndexExpanded, expandOnKeyNavigation, toggleIndexExpanded } =
    useAccordion();

  const [overrideInstructions, setOverrideInstructions] = useState(
    nodeData.overrideInstructions ?? ""
  );

  const isStartNode = nodeData.nodeTypeId === NODE_TYPE_START;
  const finalStateCheckboxDisabled = isStartNode || hasOutEdges;

  const getAccordionPanel = () => {
    return (
      <>
        <Label htmlFor={"input-area-instructions" + nodeData.id}>
          Instructions (From Node Type)
        </Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id={"input-area-instructions" + nodeData.id}
            name="textarea-instructions"
            disabled
            onChange={(e) => setInstructions(e.currentTarget.value)}
            value={instructions}
            style={{ blockSize: "90px" }}
          />
        </InputContainer>

        <Label htmlFor={"input-area-overrideInstructions" + nodeData.id}>
          Override Instructions (From Node)
        </Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id={"input-area-overrideInstructions" + nodeData.id}
            name="textarea-overrideInstructions"
            onChange={(e) => setOverrideInstructions(e.currentTarget.value)}
            value={overrideInstructions}
            style={{ blockSize: "70px" }}
          />
        </InputContainer>
        <Label htmlFor={"input-define-params" + nodeData.id}>
          Defines Parameters:
        </Label>
        <InputContainer className="v-flex-row">
          <Input
            id={"input-define-params" + nodeData.id}
            type="text"
            value={nodeData.nodeTypeParamsOut}
            readOnly
            disabled
          />
        </InputContainer>

        <Label htmlFor={"input-required-params" + nodeData.id}>
          Required Input Parameters:
        </Label>
        <InputContainer className="v-flex-row">
          <Input
            id={"input-required-params" + nodeData.id}
            type="text"
            value={nodeData.nodeTypeParamsIn}
            readOnly
            disabled
          />
        </InputContainer>
        <Label htmlFor={"input-tools" + nodeData.id}>
          Tools (From Node Type)
        </Label>
        <div className="tools-code-div" tabIndex="0">
          <CodeBlockDisplay
            id={"input-tools" + nodeData.id}
            code={tools}
            language={"JSON"}
          />
        </div>
      </>
    );
  };

  const accordions = [
    {
      header: "Advanced Settings",
      content: getAccordionPanel(),
    },
  ];

  const saveNodeChanges = () => {
    console.log("saveNodeChanges", nodeData.id, nodeData);

    nodeData.nodeName = name;
    nodeData.label = name;
    nodeData.nodeDescription = desc;
    nodeData.overrideInstructions = overrideInstructions;
    nodeData.nodeMessage = message;
    nodeData.finalNode = finalNode;

    nodeData.onUpdateNode(nodeData.id, nodeData);
    ref.current?.close();
  };

  const toggleFinalState = () => {
    if (finalNode === true) {
      setFinalNode(false);
    } else {
      setFinalNode(true);
    }
  };

  return (
    <>
      <NodeToolbar
        className="nowheel"
        isVisible={undefined}
        position={Position.Top}
      >
        <div>
          <Button
            onClick={() => {
              //nodeData.removeSelection();
              ref.current?.showModal();
            }}
            colorScheme="secondary"
          >
            <VisaEditLow />
            Edit
          </Button>{" "}
          &nbsp;
          <Button
            onClick={() => nodeData.onDeleteNode(nodeData.id)}
            colorScheme="secondary"
          >
            <VisaDeleteLow />
            Remove
          </Button>
        </div>
      </NodeToolbar>

      <Dialog
        aria-labelledby={"update-node-details-header" + nodeData.id}
        id="dialog-update-node-details"
        ref={ref}
        onKeyDown={(e) => onKeyNavigation(e, ref.current?.open)}
      >
        <DialogContent>
          <DialogHeader id={"update-node-details-header" + nodeData.id}>
            Update State Details
          </DialogHeader>
          <Label htmlFor={"input-default" + nodeData.id}>Name</Label>
          <InputContainer className="v-flex-row">
            <Input
              id={"input-default" + nodeData.id}
              type="text"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </InputContainer>
          <Label htmlFor={"input-area-desc" + nodeData.id}>Description</Label>
          <InputContainer className="v-flex-row">
            <Textarea
              fixed
              id={"input-area-desc" + nodeData.id}
              name="textarea-desc"
              onChange={(e) => setDesc(e.currentTarget.value)}
              value={desc}
              style={{ blockSize: "70px" }}
            />
          </InputContainer>

          <fieldset
            aria-labelledby={
              "checkbox-panel-final-flow-state-desc=message" + nodeData.id
            }
            style={{ marginBottom: 20 }}
            disabled={finalStateCheckboxDisabled}
          >
            <CheckboxPanel
              htmlFor="checkbox-panel-message"
              className="v-align-items-start"
              onClick={() => toggleFinalState()}
            >
              <Utility vFlex vGap={2} style={{ inlineSize: "100%" }}>
                <Checkbox
                  id="checkbox-final"
                  name="checkbox-test-10"
                  className="v-flex-shrink-0"
                  checked={finalNode}
                  onChange={() => toggleFinalState()}
                />
                <Utility vFlex vFlexCol vGap={2} vMarginVertical={8}>
                  Final Flow State
                  <InputMessage
                    id={
                      "checkbox-panel-final-flow-state-desc=message" +
                      nodeData.id
                    }
                  >
                    This marks the end of the interaction with the cardholder in
                    this flow.
                  </InputMessage>
                </Utility>
              </Utility>
            </CheckboxPanel>
          </fieldset>

          <Label htmlFor={"text-area-fixed-text-response" + nodeData.id}>
            Fixed Text Response (Optional)
          </Label>
          <InputContainer className="v-flex-row">
            <Textarea
              fixed
              id={"text-area-fixed-text-response" + nodeData.id}
              name="textarea-desc"
              onChange={(e) => setMessage(e.currentTarget.value)}
              value={message}
              style={{ blockSize: "70px" }}
            />
          </InputContainer>

          <Accordion
            id="accordion-key-nav-group"
            onKeyDown={expandOnKeyNavigation}
            tag="div"
          >
            {accordions.map((accordion, i) => (
              <Fragment key={i}>
                <AccordionHeading
                  aria-controls={`accordion-key-nav-group-panel-${
                    i + "-" + nodeData.id
                  }-advanced`}
                  aria-expanded={isIndexExpanded(i)}
                  buttonSize="large"
                  colorScheme="secondary"
                  id={`accordion-key-nav-group-header-${i + "-" + nodeData.id}`}
                  onClick={() => toggleIndexExpanded(i)}
                  tag="button"
                >
                  <AccordionToggleIcon accordionOpen={isIndexExpanded(i)} />
                  {accordion.header}
                </AccordionHeading>
                <AccordionPanel
                  aria-hidden={!isIndexExpanded(i)}
                  id={`accordion-key-nav-group-panel-${
                    i + "-" + nodeData.id
                  }-advanced`}
                >
                  <Typography tag="span">{accordion.content}</Typography>
                </AccordionPanel>
              </Fragment>
            ))}
          </Accordion>

          <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-end">
            <Button onClick={() => saveNodeChanges()}>Save</Button>
            <Button
              colorScheme="secondary"
              onClick={() => ref.current?.close()}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
        <DialogCloseButton onClick={() => ref.current?.close()} />
      </Dialog>
    </>
  );
};

export default NodeContext;
