import {
  Typography,
  Progress,
  ProgressLabel,
  ContentCard,
  ContentCardTitle,
  ContentCardBody,
  Divider,
  BannerIcon,
  Banner,
  BannerContent,
} from "@visa/nova-react";
import { VisaSecurityLockHigh } from "@visa/nova-icons-react";
import React, { useState, useEffect } from "react";
import apiConfig from "../utils/apiConfig";
import { isBackdoorEnabled } from "../utils/constants";
import UserSelect from "./UserSelect";
import { BASE_PATH } from "../utils/authUtils";

const Login = () => {
  const [callFailed, setCallFailed] = useState(false);
  const [backdoorEnabled, SetBackdoorEnabled] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [showContactAdmin, setShowContactAdmin] = useState(false);

  useEffect(() => {
    console.log("Is Backdoor enabled: " + isBackdoorEnabled());
    SetBackdoorEnabled(isBackdoorEnabled());
    console.log("call authenticate");
    setTimeout(() => {
      doLogin("dummyUser");
    }, 3000);
  }, []);

  useEffect(() => {
    setShowContactAdmin(callFailed === true && !backdoorEnabled);
  }, [callFailed]);

  const showBackdoor = () => {
    return callFailed === true && backdoorEnabled === true;
  };

  const doLogin = (volId) => {
    console.log(" login for ", volId);
    apiConfig
      .post(
        "/authenticate",
        {},
        {
          headers: {
            sm_login: volId,
          },
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((res) => {
        setShowProgressBar(false);
        console.log(res);
        const csrfToken = res.headers.get("X_csrf_token");

        localStorage.setItem("currentUser", JSON.stringify(res.data));
        localStorage.setItem("csrfToken", csrfToken);
        window.location = BASE_PATH;
      })
      .catch(function (error) {
        setShowProgressBar(false);
        setCallFailed(true);
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          marginTop: 20,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        <ContentCard>
          <ContentCardBody className="v-flex v-flex-col v-gap-4">
            <VisaSecurityLockHigh />
            <ContentCardTitle variant="headline-4">
              You need a VOL Session
            </ContentCardTitle>
            <Divider />
            <Typography className="v-pt-4">
              This application is only accessible via VOL Login
            </Typography>
            {showProgressBar && (
              <>
                <div style={{ marginTop: 30 }}>
                  <Typography className="v-pt-4">Logging you in...</Typography>
                  <Progress className="v-mb-8" />
                  <ProgressLabel htmlFor="progress-bar"></ProgressLabel>
                </div>
              </>
            )}

            {showBackdoor() && (
              <div style={{ marginTop: 30 }}>
                Could not find valid VOL credentials in your session.
                <Banner messageType="info" style={{ marginTop: 10 }}>
                  <BannerIcon />
                  <BannerContent className="v-pl-2 v-pb-2">
                    <Typography>
                      This environment has developer backdoor enabled
                    </Typography>
                  </BannerContent>
                </Banner>
                <UserSelect />
              </div>
            )}
            {showContactAdmin && (
              <>
                <div style={{ marginTop: 30 }}>
                  <Banner messageType="warning">
                    <BannerIcon />
                    <BannerContent className="v-pl-2 v-pb-2">
                      <Typography>
                        Could not find valid VOL credentials in your session.
                        Please contact an administrator.
                      </Typography>
                    </BannerContent>
                  </Banner>
                </div>
              </>
            )}
          </ContentCardBody>
        </ContentCard>
      </div>
    </>
  );
};

export default Login;
