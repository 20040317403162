import dagre from "dagre";
import { edgeSettings } from "./constants";

const nodeWidth = 300;
const nodeHeight = 300;

let id = 10000;
export const getId = () => "temp" + id++;

export const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    //console.log(node.id, node.position);

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export const getGraphForSave = (bid, flowId, version, nodes, edges) => {
  return {
    bid: bid,
    flowId: flowId,
    version: version,
    nodes: nodeDtos(nodes),
    edges: edgeDtos(edges),
  };
};

const nodeDtos = (nodes) => {
  return nodes.map((node) => {
    return {
      nodeId: node.data.id,
      nodeTypeId: node.data.nodeTypeId,
      nodeName: node.data.nodeName,
      nodeDescription: node.data.nodeDescription,
      tools: node.data.tools,
      instructions: node.data.instructions,
      overrideInstructions: node.data.overrideInstructions,
      nodeMessage: node.data.nodeMessage,
      finalNode: node.data.finalNode,
    };
  });
};

const edgeDtos = (edges) => {
  return edges.map((edge) => {
    return {
      edgeId: edge.id,
      edgeName: edge.name ?? edge.label,
      sourceNodeId: edge.source,
      destinationNodeId: edge.target,
    };
  });
};

export const createNode = (
  nodeId,
  position,
  label,
  nodeTypeId,
  nodeDataObject,
  onDeleteNode,
  onUpdateNode,
  removeSelection
) => {
  return {
    id: nodeId,
    position: position,
    data: {
      ...nodeDataObject,
      nodeTypeParamsIn: nodeDataObject.nodeTypeParamsIn,
      nodeTypeParamsOut: nodeDataObject.nodeTypeParamsOut,
      label: label,
      id: nodeId,
      nodeTypeId: nodeTypeId,
      nodeMessage: nodeDataObject.nodeMessage,
      finalNode: nodeDataObject.finalNode === true,
      onDeleteNode: onDeleteNode,
      onUpdateNode: onUpdateNode,
      removeSelection: removeSelection,
      missingParams: [],
    },
    type: nodeTypeId,
    className: "node",
    dragHandle: ".v-content-card-body",
    label: label,
  };
};

export const createEdge = (
  edgeId,
  sourceNodeId,
  destinationNodeId,
  edgeDataObject
) => {
  return {
    id: edgeId,
    source: sourceNodeId,
    target: destinationNodeId,
    data: { ...edgeDataObject, label: edgeDataObject.edgeName },
    label: edgeDataObject.edgeName,
    ...edgeSettings,
  };
};
