export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  localStorage.removeItem("csrfToken");
};

export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("currentUser") !== null &&
    localStorage.getItem("currentUser") !== ""
  );
};

export const setCurrentUser = (user) => {
  console.log(" Setting current user", user);
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const getCsrfToken = () => {
  return localStorage.getItem("csrfToken");
};

export const isSandboxEnvironment = (profile) => {
  return profile === "cteocc" || profile === "cteoce";
};

export const BASE_PATH = "";
export const LOGIN_PATH = BASE_PATH + "/#/login";
export const LOGOUT_PATH = BASE_PATH + "/#/logout";
