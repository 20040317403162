import axios from "axios";
import { LOGIN_PATH, getCsrfToken } from "./authUtils";

const instance = axios.create({
  baseURL: "",
  //local only
  //baseURL: "http://localhost:8553/",
});

instance.defaults.headers.common["X-CSRF-TOKEN"] = getCsrfToken();
instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      console.log("Unautorized resp. - attempting auto-login");
      window.location = LOGIN_PATH;
    }
    return Promise.reject(error);
  }
);

export default instance;
