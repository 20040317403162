import React, { useCallback, useEffect } from "react";
import { useReactFlow } from "reactflow";
import { edgeSettings } from "../../utils/constants";
import { DropdownMenu, Listbox, ListboxItem } from "@visa/nova-react";
import { getId } from "../../utils/graphUtils";
import {
  VisaSuccessTiny,
  VisaTransactionsNewTiny,
  VisaCurrencyConvertTiny,
  VisaCloseTiny,
  VisaMediaStopAltTiny,
  VisaMessageTiny,
  VisaMediaPlayAltTiny,
} from "@visa/nova-icons-react";

export default function ContextMenu({
  id,
  pos,
  top,
  left,
  right,
  bottom,
  isOpen,
  baNodeTypes,
  onDeleteNode,
  onUpdateNode,
  setMenuLoading,
  ...props
}) {
  const { getNode, addNodes, setEdges } = useReactFlow();

  useEffect(() => {
    setMenuLoading(false);
  }, []);

  const createNode = useCallback((type) => {
    addNodeOfType(type);
  }, []);

  const listboxItems = [
    {
      title: "Start",
      nodeTypeId: 1,
      icon: <VisaMediaPlayAltTiny />,
      enabled: false,
    },
    {
      title: "Transaction Lookup",
      nodeTypeId: 2,
      icon: <VisaTransactionsNewTiny />,
      enabled: true,
    },
    {
      title: "Found Transaction",
      nodeTypeId: 3,
      icon: <VisaSuccessTiny />,
      enabled: true,
    },
    {
      title: "Transaction Not Found",
      nodeTypeId: 4,
      icon: <VisaCloseTiny />,
      enabled: true,
    },
    {
      title: "Stop Payment",
      nodeTypeId: 5,
      icon: <VisaMediaStopAltTiny />,
      enabled: true,
    },
    {
      title: "SPS Success",
      nodeTypeId: 6,
      icon: <VisaSuccessTiny />,
      enabled: true,
    },
    {
      title: "SPS Failed",
      nodeTypeId: 7,
      icon: <VisaCloseTiny />,
      enabled: true,
    },
    {
      title: "Monthly Recurring Payment Lookup",
      nodeTypeId: 8,
      icon: <VisaCurrencyConvertTiny />,
      enabled: true,
    },
    {
      title: "Static Message",
      nodeTypeId: 9,
      icon: <VisaMessageTiny />,
      enabled: true,
    },
  ];

  const addNodeOfType = (type) => {
    const node = getNode(id);
    const position = pos;
    const typeInfo = baNodeTypes.find((obj) => {
      return obj.nodeTypeId === type;
    });

    const newId = getId();
    addNodes({
      ...node,
      id: newId,
      position,
      type: type,
      data: {
        id: newId,
        nodeName: typeInfo.nodeTypeName,
        nodeDescription: typeInfo.nodeTypeDescription,
        nodeTypeId: type,
        instructions: typeInfo.nodeTypeInstructions,
        tools: typeInfo.nodeTypeTools,
        nodeTypeParamsIn: typeInfo.nodeTypeParamsIn,
        nodeTypeParamsOut: typeInfo.nodeTypeParamsOut,
        onDeleteNode: onDeleteNode,
        onUpdateNode: onUpdateNode,
        nodeMessage: "",
        finalNode: false,
        missingParams: [],
      },
    });
    const newEdgeId = getId();
    console.log(newEdgeId);
    setEdges((eds) =>
      eds.concat({
        id: newEdgeId,
        source: id,
        target: newId,
        ...edgeSettings,
      })
    );
  };

  return (
    <>
      <div
        style={{ top, left, right, bottom }}
        className="context-menu"
        aria-controls="dropdown-menu-default"
        aria-haspopup="menu"
        aria-owns="dropdown-menu-default"
        id="dropdown-button-default"
        {...props}
      >
        <DropdownMenu
          aria-labelledby="dropdown-button-default"
          id="dropdown-menu-default"
          aria-label="menu-list-box"
          aria-busy="true"
          role="listbox"
          style={{
            top,
            left,
            right,
            bottom,
            zIndex: 10,
          }}
        >
          <Listbox>
            {listboxItems.map((item) => (
              <ListboxItem
                className="v-px-8 v-py-11 v-pr-24"
                key={item.nodeTypeId + item.title}
                tag="button"
                onClick={() => createNode(item.nodeTypeId)}
                disabled={item.enabled === false}
              >
                {item.icon}
                <span className="context-menu-title">{item.title}</span>
              </ListboxItem>
            ))}
          </Listbox>
        </DropdownMenu>
      </div>
    </>
  );
}
