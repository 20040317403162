import { Handle } from "reactflow";

const InHandle = () => {
  return (
    <>
      <Handle className="in-handle" type="target" position="left" />
      <div className="in-handle-div"></div>
    </>
  );
};

export default InHandle;
