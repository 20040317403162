import FlowNode from "../components/node/FlowNode.jsx";
import { MarkerType } from "reactflow";

import {
  VisaSuccessHigh,
  VisaCloseHigh,
  VisaMediaPlayAltHigh,
  VisaTransactionsNewHigh,
  VisaMediaStopAltHigh,
  VisaCurrencyConvertHigh,
  VisaMessageHigh,
} from "@visa/nova-icons-react";

export const nodeTypes = {
  1: FlowNode, //Start for Stop Payments
  2: FlowNode, //Transaction Lookup for Stop Payments
  3: FlowNode, //Found Transaction for Stop Payments
  4: FlowNode, //Transaction Not Found
  5: FlowNode, //Stop Payment
  6: FlowNode, //SPS Success
  7: FlowNode, //SPS Failed
  8: FlowNode, //Transaction Lookup
  9: FlowNode, //Static Message
  10: FlowNode, //Start for Transaction Analysis
  11: FlowNode, //Found Transaction
};

export const nodeTypeIcons = {
  1: <VisaMediaPlayAltHigh />,
  2: <VisaTransactionsNewHigh />,
  3: <VisaSuccessHigh />,
  4: <VisaCloseHigh />,
  5: <VisaMediaStopAltHigh />,
  6: <VisaSuccessHigh />,
  7: <VisaCloseHigh />,
  8: <VisaCurrencyConvertHigh />,
  9: <VisaMessageHigh />,
  10: <VisaMediaPlayAltHigh />,
  11: <VisaSuccessHigh />,
};

export const nodeTypeColors = {
  1: "info",
  2: "info",
  3: "success",
  4: "error",
  5: "info",
  6: "success",
  7: "error",
  8: "info",
  9: "static",
  10: "info",
  11: "success",
};

export const nodeTypeParams = {
  1: [],
  2: ["Card", "Start Date", "End Date"],
  3: [],
  4: [],
  5: ["Card", "Merchant Name", "Duration"],
  6: [],
  7: [],
  8: ["Card", "Start Date", "End Date"],
  9: [],
  10: [],
  11: [],
};

export const NODE_TYPE_START = 1;

export const isBackdoorEnabled = () => {
  return process.env.REACT_APP_BACKDOOR_ENABLED === "true";
};

export const edgeSettings = {
  type: "smoothstep",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: "#3367d9",
  },
  style: {
    strokeWidth: 2,
    stroke: "#3367d9",
  },
};

export const LATEST_DRAFT_OVERRIDE_MESSAGE =
  "You Are Attempting To Override Your Latest Draft";
