import React, { useState, useEffect, useRef } from "react";
import FlowListItem from "./FlowListItem";
import apiConfig from "../../utils/apiConfig";
import Typography from "@visa/nova-react/typography";
import { Button, Divider } from "@visa/nova-react";
import ContentCard from "@visa/nova-react/content-card";
import ContentCardBody from "@visa/nova-react/content-card-body";
import { VisaAddAltHigh } from "@visa/nova-icons-react";
import { SuccessBanner } from "../menu/SuccessBanner";
import { ProgressBar } from "../menu/ProgressBar";
import { FailedBanner } from "../menu/FailedBanner";
import { AddNewFlowModal } from "../menu/dialog/AddNewFlowModal";
import { LOGIN_PATH } from "../../utils/authUtils";

const FlowList = () => {
  const [flows, setFlows] = useState([]);
  const [bid, setBid] = useState(null);

  const [showProgressBar, setShowProgressBar] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [flowTemplates, setFlowTemplates] = useState([]);
  const [selectedFlowRecord, setSelectedFlowRecord] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const addNewFlowModalRef = useRef();

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      setBid(currentUser.userBid);
    } else {
      window.location = LOGIN_PATH;
    }

    if (bid !== null) {
      loadFlows();

      apiConfig
        .get("/banking-assistant-web/v1/flowdata/templates")
        .then((res) => {
          setFlowTemplates(res.data);
        })
        .catch(function (error) {
          console.log(error);
          setErrorMessage("Could not load Templates");
          setShowFailed(true);
        });
    }
  }, [bid]);

  const loadFlows = () => {
    apiConfig
      .get("/banking-assistant-web/v1/flowdata/flowinfo/bid/" + bid)
      .then((res) => {
        setFlows(res.data);
        setShowProgressBar(false);
        console.log(flows);
      })
      .catch(function (error) {
        setErrorMessage("Could not load Flow Data");
        setShowProgressBar(false);
        setShowFailed(true);
      });
  };

  const disableFlow = (flowId) => {
    if (bid !== null) {
      setShowProgressBar(true);
      apiConfig
        .post(
          "/banking-assistant-web/v1/flowdata/flowinfo/bid/" +
            bid +
            "/disable/flowId/" +
            flowId
        )
        .then((res) => {
          displaySuccessMessage("Flow Successfully Disabled");
          console.log(res.data);
          loadFlows();
        })
        .catch(function (error) {
          displayErrorMessage("Could not update Configuration");
          console.log(error);
        });
    }
  };

  const enableFlow = (flowId) => {
    if (bid !== null) {
      setShowProgressBar(true);
      apiConfig
        .post(
          "/banking-assistant-web/v1/flowdata/flowinfo/bid/" +
            bid +
            "/enable/flowId/" +
            flowId
        )
        .then((res) => {
          displaySuccessMessage("Flow Successfully Enabled");
          console.log(res.data);
          loadFlows();
        })
        .catch(function (error) {
          displayErrorMessage("Could not update Configuration");
          console.log(error);
        });
    }
  };

  const displaySuccessMessage = (message) => {
    setShowProgressBar(false);
    setSuccessMessage(message);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
  };

  const displayErrorMessage = (message) => {
    setShowProgressBar(false);
    setErrorMessage(message);
    setShowFailed(true);
    setTimeout(() => {
      setShowFailed(false);
    }, 3000);
  };

  const showModal = (isEdit, flowRecord) => {
    setIsEdit(isEdit);
    if (isEdit === true) {
      setSelectedFlowRecord(flowRecord);
    } else {
      setSelectedFlowRecord(null);
    }
    addNewFlowModalRef.current?.showModal();
  };

  return (
    <>
      {showSuccess && <SuccessBanner message={successMessage} />}
      {showFailed && <FailedBanner message={errorMessage} />}
      {showProgressBar && <ProgressBar />}

      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          marginTop: 20,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        {flows.map(
          (flowRecord) =>
            flowRecord.activeIndicator === "Y" && (
              <FlowListItem
                key={flowRecord.flowId}
                flowRecord={flowRecord}
                disableFlow={disableFlow}
                enableFlow={enableFlow}
                showModal={showModal}
                activeIndicator={flowRecord.activeIndicator}
                className={"flow-list-item"}
              />
            )
        )}

        <ContentCard
          className={"flow-list"}
          style={{ marginBottom: 20, border: "5px dashed #3367d94d" }}
        >
          <ContentCardBody className="v-flex v-flex-col v-gap-12">
            <VisaAddAltHigh />
            <div>
              <Typography variant="subtitle-1"> Enable new Flow </Typography>
              <Typography className="v-text-subtle">
                Select this option to enable new Conversation Flow
              </Typography>
            </div>
            <div className="v-flex v-gap-inherit">
              <Button onClick={() => showModal(false)}>Add New Flow</Button>
            </div>
          </ContentCardBody>
        </ContentCard>
        <Divider style={{ marginBottom: 20 }} />

        {flows.map(
          (flowRecord) =>
            flowRecord.activeIndicator === "N" && (
              <FlowListItem
                key={flowRecord.flowId}
                flowRecord={flowRecord}
                disableFlow={disableFlow}
                enableFlow={enableFlow}
                activeIndicator={flowRecord.activeIndicator}
                className={"flow-list-item-disabled"}
              />
            )
        )}
      </div>

      <AddNewFlowModal
        ref={addNewFlowModalRef}
        templates={flowTemplates}
        selectedFlowRecord={selectedFlowRecord}
        bid={bid}
        displaySuccessMessage={displaySuccessMessage}
        displayErrorMessage={displayErrorMessage}
        loadFlows={loadFlows}
        isEdit={isEdit}
      />
    </>
  );
};

export default FlowList;
