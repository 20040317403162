import React, { useState, useRef, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  ConnectionLineType,
  getOutgoers,
} from "reactflow";
import "reactflow/dist/style.css";

import "../../index.css";
import Sidebar from "./Sidebar";
import { nodeTypes, edgeSettings } from "../../utils/constants";
import {
  getLayoutedElements,
  getGraphForSave,
  createNode,
  createEdge,
  getId,
} from "../../utils/graphUtils";
import apiConfig from "../../utils/apiConfig";
import ContextMenu from "./ContextMenu";
import FlowDetails from "./FlowDetails";
import { SuccessBanner } from "../menu/SuccessBanner";
import { ProgressBar } from "../menu/ProgressBar";
import { FailedBanner } from "../menu/FailedBanner";
import { ConfirmSaveModal } from "../menu/dialog/ConfirmSaveModal";
import { ConfirmUnpublishModal } from "../menu/dialog/ConfirmUnpublishModal";
import "../node/node-common/node-common.css";

const initialNodes = [];
const initialEdges = [];

const GraphMain = () => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const confirmSaveModalRef = useRef();
  const confirmUnpublishModalRef = useRef();

  const [bid, setBid] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [baNodeTypes, setBaNodeTypes] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [flowDetails, setFlowDetails] = useState(null);
  const [flowInfo, setFlowInfo] = useState(null);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailed, setShowFailed] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [changePending, setChangePending] = useState(false);
  const [envProfile, setEnvProfile] = useState("");

  const { flowId } = useParams();

  const ref = useRef(null);
  const [menu, setMenu] = useState(null);
  const [menuLoading, setMenuLoading] = useState(false);
  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => {
    if (!menuLoading) setMenu(null);
  }, [setMenu, menuLoading]);

  useEffect(() => {
    document.addEventListener("keydown", onPaneClick, false);

    return () => {
      document.removeEventListener("keydown", onPaneClick, false);
    };
  }, [onPaneClick]);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      setBid(currentUser.userBid);
    }
    if (bid !== null) {
      apiConfig
        .get("/banking-assistant-web/v1/nodetype/bid/" + bid)
        .then((res) => {
          setBaNodeTypes(res.data);
          setEnvProfile(res.headers["x-env-profile"]);
        })
        .catch(function (error) {
          console.log(error);
          setShowFailed(true);
        });

      loadGraphWithVersion(null);
      loadVersionInfo();
    }
  }, [bid]);

  const loadVersionInfo = (selectlatest) => {
    apiConfig
      .get(
        "/banking-assistant-web/v1/flowdata/flowinfo/bid/" +
          bid +
          "/flowId/" +
          flowId
      )
      .then((res) => {
        setFlowInfo(res.data[0]);
        setEnvProfile(res.headers["x-env-profile"]);
        console.log(flowInfo);
        if (selectlatest && selectlatest === true) {
          onVersionSelectionChange(flowInfo.version);
        }
      })
      .catch(function (error) {
        console.log(error);
        setShowFailed(true);
      });
  };

  const loadGraphWithVersion = (version) => {
    apiConfig
      .get(
        "/banking-assistant-web/v1/graph/bid/" +
          bid +
          "/flow/" +
          flowId +
          (version ? "/version/" + version : "")
      )
      .then((res) => {
        //console.log(res);
        setFlowDetails(res.data);
        setSelectedVersion(res.data.version);

        const ns = res.data.nodes;
        const es = res.data.edges;
        const addNodes = [];
        const addEdges = [];

        ns.map((n) => {
          const nodeId = n.nodeId;

          if (!addNodes.some((el) => el.id === nodeId)) {
            addNodes.push(
              createNode(
                nodeId,
                { x: 0, y: 0 },
                n.nodeName,
                n.nodeTypeId,
                n,
                onDeleteNode,
                onUpdateNode,
                removeSelection
              )
            );
          }
        });

        es.map((e) => {
          const edgeId = e.edgeId;
          const sourceNodeId = e.sourceNodeId;
          const destinationNodeId = e.destinationNodeId;

          if (!addEdges.some((el) => el.id === edgeId)) {
            addEdges.push(
              createEdge(edgeId, sourceNodeId, destinationNodeId, e)
            );
          }
        });

        setNodes(addNodes);
        setEdges(addEdges);
        getLayoutedElements(addNodes, addEdges, "LR");
        setShowProgressBar(true);
        setTimeout(() => {
          reactFlowInstance?.fitView();
          setShowProgressBar(false);
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        setShowFailed(true);
      });
  };

  const onNodeContextMenu = useCallback(
    (event, nodeId, pos) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();
      setMenuLoading(true);
      setMenu({
        id: nodeId,
        pos: pos,

        top: event.clientY < pane.height - 250 && event.clientY,
        left: event.clientX < pane.width - 350 && event.clientX,
        right: event.clientX >= pane.width - 350 && pane.width - event.clientX,
        bottom:
          event.clientY >= pane.height - 900 &&
          pane.height - event.clientY + 200,
        isOpen: true,
        baNodeTypes: baNodeTypes,
        onDeleteNode: onDeleteNode,
        onUpdateNode: onUpdateNode,
        setMenuLoading: setMenuLoading,
      });
    },
    [setMenu, baNodeTypes]
  );

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            ...edgeSettings,
          },
          eds
        )
      ),
    []
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    onPaneClick();
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (!connectingNodeId.current) return;

      if (targetIsPane) {
        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        onNodeContextMenu(event, connectingNodeId.current, position);
      }
    },
    [reactFlowInstance, baNodeTypes]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      console.log(baNodeTypes);
      const nodeTypeInfo = baNodeTypes.find(
        (x) => x.nodeTypeId === parseInt(type)
      );

      const new_id = getId();
      const newNode = createNode(
        new_id,
        position,
        "new node",
        type,
        {
          nodeName: nodeTypeInfo.nodeTypeName,
          nodeDescription: nodeTypeInfo.nodeTypeDescription,
          instructions: nodeTypeInfo.nodeTypeInstructions,
          tools: nodeTypeInfo.nodeTypeTools,
          nodeTypeId: nodeTypeInfo.nodeTypeId,
          nodeTypeParamsIn: nodeTypeInfo.nodeTypeParamsIn,
          nodeTypeParamsOut: nodeTypeInfo.nodeTypeParamsOut,
        },
        onDeleteNode,
        onUpdateNode,
        removeSelection
      );

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, baNodeTypes]
  );

  const onDeleteNode = useCallback(
    (id) => {
      console.log("onDeleteNode", id);
      setChangePending(true);

      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.target !== id && edge.source !== id)
      );
    },
    [nodes, edges]
  );

  const removeSelection = useCallback(() => {
    setNodes((nds) =>
      nds.map((node) => (node = { ...node, selected: false, select: false }))
    );
  }, []);

  const onUpdateNode = useCallback((id, updatedNode) => {
    console.log("onUpdateNode", id, updatedNode);
    setChangePending(true);

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          // it's important that you create a new object here
          // in order to notify react flow about the change

          node = {
            ...node,
            selected: false,
            select: false,
            data: updatedNode,
          };
        }

        return node;
      })
    );
  }, []);

  const onAdjustLayout = () => {
    console.log("onAdjustLayout called!!");
    onLayout("LR");
  };

  const onSaveFlow = (publish) => {
    console.log("onSaveFlow called!!");

    console.log("Version: ", flowDetails.version);
    console.log("Latest Version: ", flowInfo.latestVersion);
    console.log("Published Version: ", flowInfo.publishedVersion);
    if (flowDetails.version !== flowInfo.latestVersion) {
      console.log("not on latest version");
      confirmSaveModalRef.current?.showModal();
    } else {
      saveConfirmed(publish);
    }
  };

  const saveConfirmed = (publish) => {
    onLayout("LR");
    setShowProgressBar(true);
    apiConfig
      .post(
        "/banking-assistant-web/v1/graph/bid/" +
          bid +
          "/flow/" +
          flowId +
          (publish === true ? "/publish" : ""),
        getGraphForSave(bid, flowId, flowDetails.version, nodes, edges)
      )
      .then((res) => {
        console.log(res);
        setFlowDetails(res.data);
        displaySuccessMessage(
          (publish === true ? "Publish" : "Save") + " Successful"
        );
        loadVersionInfo(true);
        confirmSaveModalRef.current?.closeModal();
        setChangePending(false);
      })
      .catch(function (error) {
        console.log(error);
        displayErrorMessage("Could not save");
        confirmSaveModalRef.current?.closeModal();
      });
  };

  const onUnPublish = () => {
    console.log("unPublish called!!");

    console.log("Version: ", flowDetails.version);
    console.log("Latest Version: ", flowInfo.latestVersion);
    console.log("Published Version: ", flowInfo.publishedVersion);

    confirmUnpublishModalRef.current?.showModal();
  };

  const unPublishConfirmed = () => {
    setShowProgressBar(true);
    apiConfig
      .post(
        "/banking-assistant-web/v1/graph/bid/" +
          bid +
          "/flow/" +
          flowId +
          "/unpublish",
        {}
      )
      .then((res) => {
        console.log(res);
        displaySuccessMessage("Un-Publish Successful");
        loadVersionInfo(true);
        confirmUnpublishModalRef.current?.closeModal();
        setChangePending(false);
      })
      .catch(function (error) {
        console.log(error);
        displayErrorMessage("Could not Un-Publish");
        confirmUnpublishModalRef.current?.closeModal();
      });
  };

  const onLayout = useCallback(
    (direction) => {
      checkNodeParams();

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const isValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) {
          console.log("CYCLE DETECTED!!");
          return false;
        }
        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) {
        console.log("CYCLE DETECTED!!");
        return false;
      }
      return !hasCycle(target);
    },
    [nodes, edges]
  );

  const checkNodeParams = () => {
    const startNode = nodes.find(
      (node) => node.data.startNodeIndicator === "Y"
    );
    if (startNode) {
      checkNodeParamsInner(startNode, [], []);
    }
  };

  const checkNodeParamsInner = (node, currentlyAvailableParams, visited) => {
    visited.push(node.id);
    console.debug(" --- id: " + node.id);
    console.debug(" --- name: " + node.data.nodeName);
    console.debug(" --- in: " + node.data.nodeTypeParamsIn);
    console.debug(" --- out: " + node.data.nodeTypeParamsOut);
    console.debug(" --- visited: " + visited);
    console.debug(" --- currentlyAvailableParams: " + currentlyAvailableParams);

    // check if all needed is available
    node.data.nodeTypeParamsIn.forEach((param) => {
      if (
        param &&
        param.length > 0 &&
        currentlyAvailableParams.indexOf(param) === -1
      ) {
        if (node.data.missingParams.indexOf(param) === -1)
          node.data.missingParams.push(param);
        console.error("YOU ARE MISSING: " + param);
      }
    });

    let nextAvailableParams = [...currentlyAvailableParams];
    // add output params to available for this path
    node.data.nodeTypeParamsOut.forEach((param) => {
      if (
        param &&
        param.length > 0 &&
        nextAvailableParams.indexOf(param) === -1
      ) {
        nextAvailableParams.push(param);
      }
    });

    console.debug(" --- currentlyAvailableParams: " + nextAvailableParams);
    console.debug(
      " ---------------------------------------------------------------------------------------- "
    );

    for (const outgoer of getOutgoers(node, nodes, edges)) {
      if (visited.indexOf(outgoer.id) === -1) {
        checkNodeParamsInner(outgoer, nextAvailableParams, visited);
      }
    }
  };

  const onVersionSelectionChange = (value) => {
    console.log("VERSION CHANGE TO: " + value);
    setSelectedVersion(value);
    loadGraphWithVersion(value);
  };

  const displaySuccessMessage = (message) => {
    setShowProgressBar(false);
    setSuccessMessage(message);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
  };

  const displayErrorMessage = (message) => {
    setShowProgressBar(false);
    setErrorMessage(message);
    setShowFailed(true);
    setTimeout(() => {
      setShowFailed(false);
    }, 3000);
  };

  useEffect(() => {
    function beforeUnload(e) {
      if (!changePending) return;
      e.preventDefault();
    }

    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [changePending]);

  return (
    <>
      {showSuccess && <SuccessBanner message={successMessage} />}
      {showFailed && <FailedBanner message={errorMessage} />}
      {showProgressBar && <ProgressBar />}
      {flowDetails && flowInfo && selectedVersion && (
        <FlowDetails
          flowDetails={flowDetails}
          flowInfo={flowInfo}
          onVersionSelectionChange={onVersionSelectionChange}
          onSaveFlow={onSaveFlow}
          onAdjustLayout={onAdjustLayout}
          onUnPublish={onUnPublish}
          selectedVersion={selectedVersion}
          envProfile={envProfile}
        />
      )}

      <div className="dndflow" style={{ height: "calc(100vh - 210px)" }}>
        <ReactFlowProvider>
          <div
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
            style={{ height: "calc(100vh - 210px)" }}
          >
            <ReactFlow
              ref={ref}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              onConnectEnd={onConnectEnd}
              onConnectStart={onConnectStart}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onPaneClick={onPaneClick}
              isValidConnection={isValidConnection}
              preventScrolling={false}
              maxZoom={1.5}
              fitView
              connectionLineType={ConnectionLineType.SmoothStep}
              connectionLineStyle={{ strokeWidth: 2, stroke: "#3367d9" }}
            >
              <Controls />
              <Background />
              {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
            </ReactFlow>
          </div>
          <Sidebar />
        </ReactFlowProvider>
        <ConfirmSaveModal
          ref={confirmSaveModalRef}
          version={flowDetails?.version}
          flowInfo={flowInfo}
          saveConfirmed={saveConfirmed}
        />
        <ConfirmUnpublishModal
          ref={confirmUnpublishModalRef}
          unPublishConfirmed={unPublishConfirmed}
        />
      </div>
    </>
  );
};

export default GraphMain;
