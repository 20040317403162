import {
  VisaViewListHigh,
  VisaStoreClosedHigh,
  VisaChevronRightTiny,
} from "@visa/nova-icons-react";
import classnames from "classnames";
import { Button, Utility } from "@visa/nova-react";
import ContentCard from "@visa/nova-react/content-card";
import ContentCardBody from "@visa/nova-react/content-card-body";
import Typography from "@visa/nova-react/typography";

import "@visa/nova-styles/styles.css";
import "@visa/nova-styles/themes/nova/index.css";

const FlowListItem = ({
  flowRecord,
  className,
  disableFlow,
  enableFlow,
  activeIndicator,
  showModal,
}) => {
  const getEnableDisableButton = () => {
    if (activeIndicator === "Y") {
      return (
        <Button
          colorScheme="secondary"
          onClick={() => disableFlow(flowRecord.flowId)}
        >
          Disable Flow
        </Button>
      );
    } else {
      return (
        <Button
          colorScheme="secondary"
          onClick={() => enableFlow(flowRecord.flowId)}
        >
          Enable Flow
        </Button>
      );
    }
  };

  return (
    <>
      <ContentCard
        className={classnames(className)}
        style={{ marginBottom: 20, position: "relative" }}
      >
        <ContentCardBody className="v-flex v-flex-col v-gap-12">
          <div className="flow-version-info">
            <div className="v-flex v-justify-content-end">
              Last Updated: &nbsp;
              <b style={{ color: "#0041d0" }}>
                {flowRecord.latestVersionTimeStamp}
              </b>
              , Published: &nbsp;
              <b style={{ color: "#0041d0" }}>
                {flowRecord.publishedIndicator === "Y" ? "Yes " : "No"}
              </b>
            </div>
            {flowRecord.publishedIndicator === "Y" && (
              <div className="v-flex v-justify-content-end">
                Published On: &nbsp;
                <b style={{ color: "#0041d0" }}>
                  {flowRecord.publishedVersionTimeStamp}
                </b>
              </div>
            )}
          </div>
          {flowRecord.flowName === "Stop Payment" ? (
            <VisaStoreClosedHigh />
          ) : (
            <VisaViewListHigh />
          )}
          <div>
            <Typography style={{ marginBottom: 10 }} variant="subtitle-1">
              {flowRecord.flowName}
            </Typography>
            <Typography className="v-text-subtle">
              {flowRecord.flowDescription}
            </Typography>
          </div>
          <div className="flow-list-buttons v-flex v-gap-inherit">
            <Utility vFlex vFlexRow vFlexWrap vGap={4} vFlexGrow>
              {activeIndicator === "Y" && (
                <Button
                  onClick={() =>
                    (window.location.href = "#/flow/" + flowRecord.flowId)
                  }
                >
                  View / Modify Flow
                  <VisaChevronRightTiny />
                </Button>
              )}
              {flowRecord.publishedIndicator !== "Y" &&
                getEnableDisableButton()}
            </Utility>
            {activeIndicator === "Y" &&
              flowRecord.publishedIndicator !== "Y" && (
                <Button
                  colorScheme="secondary"
                  onClick={() => showModal(true, flowRecord)}
                >
                  Update Flow Description
                </Button>
              )}
          </div>
        </ContentCardBody>
      </ContentCard>
    </>
  );
};

export default FlowListItem;
