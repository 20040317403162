import Highlight from "react-highlight";

function CodeBlockDisplay({ id, code, language, showLineNumbers, theme }) {
  return (
    <Highlight id={id} className={language}>
      {code}
    </Highlight>
  );
}

export default CodeBlockDisplay;
