import {
  Banner,
  BannerContent,
  BannerIcon,
  Typography,
} from "@visa/nova-react";

export const FailedBanner = ({ message }) => {
  return (
    <>
      <Banner role="alert" messageType="warning">
        <BannerIcon />
        <BannerContent>
          <Typography>
            Some Error Happened
            {message && <span> - {message} </span>}
          </Typography>
        </BannerContent>
      </Banner>
    </>
  );
};
