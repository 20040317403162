import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  Typography,
} from "@visa/nova-react";
import useFocusTrap from "../../../utils/useFocusTrap.tsx";
import React, { forwardRef, useImperativeHandle } from "react";

export const ConfirmUnpublishModal = forwardRef(
  ({ unPublishConfirmed }, fref) => {
    const { onKeyNavigation, ref } = useFocusTrap();

    useImperativeHandle(fref, () => ({
      showModal() {
        ref.current?.showModal();
      },
      closeModal() {
        ref.current?.close();
      },
    }));

    return (
      <>
        <Dialog
          aria-describedby="confirm-unpublished-dialog-description"
          aria-labelledby="confirm-unpublished-dialog-title"
          id="dialog"
          ref={ref}
          onKeyDown={(e) => onKeyNavigation(e, ref.current?.open)}
        >
          <DialogContent>
            <DialogHeader id="confirm-unpublished-dialog-title">
              You Are Attempting To Remove Published Version
            </DialogHeader>
            <Typography id="confirm-unpublished-dialog-description">
              This will make the flow unavailable to end-users until a new
              Published Version is selected
            </Typography>
            <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-end">
              <Button onClick={unPublishConfirmed}>
                Ok, Remove Published Version
              </Button>
              <Button
                colorScheme="secondary"
                onClick={() => ref.current?.close()}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
          <DialogCloseButton onClick={() => ref.current?.close()} />
        </Dialog>
      </>
    );
  }
);
