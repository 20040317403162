import "./index.css";
import HorizontalAdvancedAlternativeNav from "./components/menu/Menu.jsx";
import "@visa/nova-styles/styles.css";
import "@visa/nova-styles/themes/nova/index.css";
import { createHashRouter, RouterProvider, Navigate } from "react-router-dom";

import FlowList from "./components/flow/FlowList.jsx";
import About from "./components/About.jsx";
import Configuration from "./components/Configuration.jsx";
import ErrorPage from "./components/ErrorPage.jsx";
import GraphMain from "./components/graph/GraphMain.jsx";
import Login from "./components/Login.jsx";
import Logout from "./components/Logout.jsx";

const router = createHashRouter([
  {
    path: "/flows",
    element: <FlowList />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/config",
    element: <Configuration />,
    errorElement: <ErrorPage />,
  },
  {
    path: "flow/:flowId",
    element: <GraphMain />,
    errorElement: <ErrorPage />,
    onLeave: () => alert("leaving"),
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Navigate replace to="/flows" />,
  },
]);

const AppMain = () => {
  return (
    <div>
      <HorizontalAdvancedAlternativeNav />
      <main id="main-content">
        <RouterProvider router={router} />
      </main>
    </div>
  );
};

export default AppMain;
