import {
  Banner,
  BannerContent,
  BannerIcon,
  Typography,
} from "@visa/nova-react";

export const SuccessBanner = ({ message }) => {
  return (
    <>
      <Banner role="alert" messageType="success">
        <BannerIcon />
        <BannerContent>
          <Typography>{message}</Typography>
        </BannerContent>
      </Banner>
    </>
  );
};
