import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  Utility,
} from "@visa/nova-react";
import useFocusTrap from "../../utils/useFocusTrap.tsx";
import React, { forwardRef, useImperativeHandle } from "react";
import { LOGOUT_PATH } from "../../utils/authUtils.jsx";

export const ProfileInfoModal = forwardRef(
  ({ bid, businessName, userId, userName, userEmail, setSignedIn }, fref) => {
    const { onKeyNavigation, ref } = useFocusTrap();

    useImperativeHandle(fref, () => ({
      showModal() {
        ref.current?.showModal();
      },
    }));

    return (
      <>
        <Dialog
          aria-describedby="profile-dialog-description"
          aria-labelledby="profile-dialog-title"
          id="dialog"
          ref={ref}
          style={{ width: "350px", minWidth: "unset" }}
          onKeyDown={(e) => onKeyNavigation(e, ref.current?.open)}
        >
          <DialogContent>
            <DialogHeader id="profile-dialog-title">User Details</DialogHeader>
            <div id="profile-dialog-description">
              <Utility vFlex vFlexRow vGap={25}>
                <Utility vFlex vFlexCol>
                  <strong>BID</strong>
                  <strong>Org Name</strong>
                  <strong>User VOL ID</strong>
                  <strong>User Name</strong>
                  <strong>Email</strong>
                </Utility>

                <Utility vFlex vFlexCol>
                  <span>{bid}</span>
                  <span>{businessName}</span>
                  <span>{userId}</span>
                  <span>{userName}</span>
                  <span>{userEmail}</span>
                </Utility>
              </Utility>
            </div>
            <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-end">
              <Button
                colorScheme="secondary"
                onClick={() => ref.current?.close()}
              >
                Close
              </Button>

              <Button
                colorScheme="secondary"
                onClick={() => {
                  ref.current?.close();
                  setSignedIn(false);
                  window.location = LOGOUT_PATH;
                }}
              >
                Sign Out
              </Button>
            </div>
          </DialogContent>
          <DialogCloseButton onClick={() => ref.current?.close()} />
        </Dialog>
      </>
    );
  }
);
